.search__bar {
    outline: none;
    border: none;
    color: var(--tg-theme-text-color);
    background-color: var(--tg-theme-secondary-bg-color);
    width: 100%;
    padding: calc(var(--px) / 3);
    font-size: 16px;
}

.search__icon {
    width: 1rem;
    height: 1rem;
    align-self: center;
}

.search__container {
    width: 100%;
    background-color: var(--tg-theme-secondary-bg-color);
    display: flex;
    border-radius: var(--px);
    padding-right: var(--px);
    padding-left: calc((var(--px) / 2) + 5px);
    max-width: 850px;
    margin: 0 auto;
}

.text-blue:before {
    border-bottom: none !important;
}