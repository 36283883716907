.transaction-detail {
  background-color: var(--tg-theme-bg-color);
  color: var(--tg-theme-text-color);
  padding: 20px;
  border-radius: 10px;
}

h2 {
  margin-bottom: 20px;
}

.detail-item {
  margin-bottom: 20px;
}

.label {
  color: #888;
  margin-bottom: 5px;
}
