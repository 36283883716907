.input-text {
    background-color: var(--tg-theme-secondary-bg-color);
    outline: none;
    color: var(--tg-theme-text-color);
    border: solid 3px var(--tg-theme-section-separator-color);
    border-radius: 8px;
    padding: 8px 10px;
    -moz-appearance: textfield;
    appearance: textfield;
}

.input-text::-webkit-inner-spin-button,
.input-text::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.error-message {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 15px;
}

.error-message h2 {
    color: #ff6b6b;
    margin-bottom: 5px;
}

.error-message p {
    color: var(--tg-theme-text-color);
    margin: 5px 0;
}