.card {
    display: flex;
    flex-direction: column;
    padding-top: .8rem;
    padding-bottom: .8rem;
    border-radius: 15px;
    transition: all .2s;
}

.card:hover {
    background-color: var(--tg-theme-secondary-bg-color);
}

.image_container {
    width: 100%;
    overflow: hidden;
}

.card__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.card__title {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

h4 {
    margin: 0;
}
.base-Menu-listbox {
    background: var(--tg-theme-bg-color) !important;
    color: var(--tg-theme-text-color) !important;
    border: 1px solid var(--tg-theme-section-separator-color) !important;
}